import './App.css';
import React from 'react'
import { useSelector } from 'react-redux';
import { Home, Presentation } from './components'

function App() {

  const isPresenting = useSelector((state) => state.settings.isPresenting)

  return (
    isPresenting? <Presentation/> : <Home/>
  )
}

export default App;
