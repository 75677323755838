import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPresenting: false,
  currentCardIndex: 0,
  isFront: true,
  presentationDone: false,
  carouselIndex: 0
}

export const settingsSlice = createSlice({
  name: "settings", 
  initialState,
  reducers: {
    setIsPresenting: (state, action) => {
      state.isPresenting = action.payload
    },
    setCurrentCardIndex: (state, action) => {
      state.currentCardIndex = action.payload
    },
    flipCard: (state) => {
      state.isFront = !state.isFront 
    },
    togglePresentationDone: (state) => {
      state.presentationDone = !state.presentationDone
    }, 
    resetSettings: state => initialState,
    setCarouselIndex: (state, action) => {
      state.carouselIndex = action.payload
    }
  }
})

export const { setIsPresenting, setCurrentCardIndex, flipCard, togglePresentationDone, resetSettings, setCarouselIndex } = settingsSlice.actions;
export default settingsSlice.reducer;