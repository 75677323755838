import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentCardIndex,
  flipCard,
  togglePresentationDone,
  resetSettings,
} from "../../redux/settingsSlice";
import "./presentationStyle.css";
import RightChevron from "../../images/chevron_right.png";
import LeftChevron from "../../images/chevron_left.png";
import Close from "../../images/close_icon.png";

const Presentation = () => {
  const dispatch = useDispatch();

  const slideData = useSelector((state) => state.sheet.data);
  const currentCard = useSelector((state) => state.settings.currentCardIndex);
  const isFrontSide = useSelector((state) => state.settings.isFront);
  const presentationDone = useSelector(
    (state) => state.settings.presentationDone
  );

  let cardSide = isFrontSide ? 0 : 1;

  const nextCard = React.useCallback(() => {
    if (
      !isFrontSide &&
      currentCard === slideData.values.length - 1 &&
      !presentationDone
    ) {
      dispatch(togglePresentationDone());
    } else if (isFrontSide) {
      dispatch(flipCard());
    } else if (!presentationDone) {
      dispatch(flipCard());
      dispatch(setCurrentCardIndex(currentCard + 1));
    }
  }, [isFrontSide, currentCard, presentationDone, dispatch, slideData]);

  const goBack = React.useCallback(() => {
    if (presentationDone) {
      dispatch(togglePresentationDone());
    } else if (isFrontSide && currentCard !== 0) {
      dispatch(flipCard());
      dispatch(setCurrentCardIndex(currentCard - 1));
    } else if (!isFrontSide) {
      dispatch(flipCard());
    }
  }, [isFrontSide, currentCard, presentationDone, dispatch]);

  const backHome = () => {
    dispatch(resetSettings());
  };

  const handleArrowKeys = React.useCallback(
    (event) => {
      event.preventDefault();
      if (event.key === "ArrowRight") {
        nextCard();
      } else if (event.key === "ArrowLeft") {
        goBack();
      }
    },
    [nextCard, goBack]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handleArrowKeys);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, [handleArrowKeys, isFrontSide, currentCard, presentationDone]);

  const presentationCards = (
    <div className="presentation-container">
      <div className="close-presentation" onClick={() => backHome()}>
        <img className="images" src={Close} alt="close" />
      </div>
      <div className="clickable left" onClick={() => goBack()}>
        <img className="arrows images" src={LeftChevron} alt="left click" />
      </div>
      <div className={isFrontSide ? "card-content bold" : "card-content"}>
        {slideData.values[currentCard][cardSide]}
      </div>
      <div className="clickable right" onClick={() => nextCard()}>
        <img className="arrows images" src={RightChevron} alt="right click" />
      </div>
    </div>
  );

  const presentationEnd = (
    <div className="presentation-container">
      <div className="clickable left" onClick={() => goBack()}>
        <img className="arrows images" src={LeftChevron} alt="left click" />
      </div>
      <div className="close-presentation" onClick={() => backHome()}>
        <img src={Close} alt="close" />
      </div>
      <div className="deck-end">End of Deck</div>
    </div>
  );

  return presentationDone ? presentationEnd : presentationCards;
};

export default Presentation;
