import React from "react";
import "./input.css";

const Input = ({ className, placeholder, onChange, name }) => {
  return (
    <input
      className={className}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    ></input>
  );
};

export default Input;
