import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shareUrl: "",
    loading: false,
    error: "",
    data: {}
}

export const sheetSlice = createSlice({
    name: 'sheet',
    initialState,
    reducers: {
        setUrl: (state, action) => {
            state.shareUrl = action.payload
        },
        isLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        resetSheet: state => initialState
    },
})

export const { setUrl, isLoading, setError, setData, resetSheet} = sheetSlice.actions;

export default sheetSlice.reducer;