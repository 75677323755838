import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "./carousel.css";

import SheetExample from "../../images/sheets_example.png";
import ShareExample from "../../images/share_button_example.png";
import CopyLinkExample from "../../images/copy_button_example.png";
import EnterUrlExample from "../../images/g2q_input.png";
import RestrictionsExample from "../../images/remove_restriction_example.png";

import RightChevronWhite from "../../images/chevron_right_white.png";
import LeftChevronWhite from "../../images/chevron_left_white.png";

import { setCarouselIndex } from "../../redux/settingsSlice";

const Carousel = () => {
  const content = [
    {
      header: `STEP #1`,
      image: SheetExample,
      alt: "Google Sheet Example",
      copy: `Using Google Sheets, build a table with two columns, one for the
      'front' & one for the 'back' values of your cue cards.`,
    },
    {
      header: `STEP #2`,
      image: ShareExample,
      alt: "Share Sheet Example",
      copy: `Click on the Share button in the top right, or in the options menu.`,
    },
    {
      header: `STEP #3`,
      image: RestrictionsExample,
      alt: `Remove Rescriptions Example`,
      copy: `Make sure the Google Sheet is public by removing any restrictions, so anyone with the link can view it.`,
    },
    {
      header: `STEP #4`,
      image: CopyLinkExample,
      alt: "Copy Link Example",
      copy: `Get the link by clicking on the 'Copy link' button.`,
    },
    {
      header: "STEP #5",
      image: EnterUrlExample,
      alt: "Enter URL Example",
      copy: `And finally, paste the URL into the input field just above, and press the "BUILD DECK" button. That's it!`,
    },
  ];

  const dispatch = useDispatch();
  const carouselIndex = useSelector((state) => state.settings.carouselIndex);

  const changeSlide = (direction) => {
    switch (direction) {
      case "forwards":
        if (carouselIndex === content.length - 1) {
          dispatch(setCarouselIndex(0));
        } else {
          dispatch(setCarouselIndex(carouselIndex + 1));
        }
        break;
      default:
        if (carouselIndex === 0) {
          dispatch(setCarouselIndex(content.length - 1));
        } else {
          dispatch(setCarouselIndex(carouselIndex - 1));
        }
        break;
    }
  };

  return (
    <div className="carousel-section">
      <div className="carousel-body">
        <div
          className="carousel-navigation"
          onClick={() => changeSlide("backwards")}
        >
          <img className="images" src={LeftChevronWhite} alt="left click" />
        </div>
        <div className="carousel-core">
          <div className="carousel-header">{content[carouselIndex].header}</div>
          <div className="carousel-content">
            <div className="carousel-image">
              <img
                src={content[carouselIndex].image}
                alt={content[carouselIndex].alt}
              />
            </div>
            <div className="carousel-copy">{content[carouselIndex].copy}</div>
          </div>
        </div>
        <div
          className="carousel-navigation"
          onClick={() => changeSlide("forwards")}
        >
          <img className="images" src={RightChevronWhite} alt="right click" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
