import React from "react";
import "./homeStyle.css";
import { Launcher, Carousel } from "../index.js";

const Home = () => {
  return (
    <div>
      <div className="container">
        <div className="main">
          <div className="description">
            Build cue cards quickly using Google Sheets.
          </div>
        </div>
        <Launcher />
        <Carousel />
        <div className="about-section">
          <div className="about-header">ABOUT</div>
          <div className="about-content">
            <div className="about-copy">
              <b>Google Sheets 2 Cue Cards</b> is a passion project developed
              and maintained by me,{" "}
              <a
                className="linkedin-link"
                href="https://www.linkedin.com/in/maxhuwgraham/"
                target="_blank"
                rel="noreferrer"
              >
                Max Graham,
              </a>{" "}
              a web developer who likes to build cool and useful things. I was
              inspired to make it after I got tired of writing old school,
              paper, cue cards by hand (Studying French!) and thought it could
              be a fun coding exercise.
            </div>
            <div className="about-copy">
              It is a work in progress, and I have more I want to add, but it
              seems good enough to share with the world, so here you go. If you
              have any suggestions or constructive feedback, please use the link
              at the bottom of the page. I hope you find it useful, happy
              studying!
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div>Version 1.0</div>
        <a
          className="feedback-link"
          href="mailto: gsheets2cuecards@gmail.com?subject=Feedback and Suggestions"
          target="_blank"
          rel="noreferrer"
        >
          Send Feedback or Suggestions
        </a>
      </footer>
    </div>
  );
};

export default Home;
