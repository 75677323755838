import React from "react";
import { CustomButton, Input } from "../index";
import {
  setUrl,
  isLoading,
  setError,
  setData,
  resetSheet,
} from "../../redux/sheetSlice";
import { setIsPresenting } from "../../redux/settingsSlice";
import { useSelector, useDispatch } from "react-redux";
import getSheets from "../../api/sheetApi";
import "./launcherStyle.css";

const Launcher = () => {
  const dispatch = useDispatch();
  const slideData = useSelector((state) => state.sheet.data);
  const url = useSelector((state) => state.sheet.shareUrl);
  const error = useSelector((state) => state.sheet.error);

  const getAndStoreData = async () => {
    dispatch(isLoading(true));
    const data = await getSheets(url);
    dispatch(setData(data));
    dispatch(isLoading(false));
  };

  const resetApp = () => {
    dispatch(resetSheet());
  };

  const submitURL = () => {
    if (url.includes(`spreadsheets/d/`)) {
      getAndStoreData();
    } else {
      dispatch(
        setError(
          "Invalid URL, please make sure you copied the correct Google Sheet link."
        )
      );
    }
  };

  const inputMode = (
    <div className="launcher-container-a">
      <Input
        className="input"
        name="url"
        placeholder="Enter a Google Sheet link here!"
        onChange={(event) => dispatch(setUrl(event.target.value))}
      />
      <CustomButton
        colour="green"
        onClick={() => {
          submitURL();
        }}
      >
        BUILD DECK
      </CustomButton>
      {error ? <div className="error">{error}</div> : null}
    </div>
  );

  const launchMode = (
    <div className="launcher-container-b">
      <div className="launcher-sheet-title">{slideData.title}</div>
      <div className="button-container">
        <CustomButton colour="red" onClick={() => resetApp()}>
          RESET
        </CustomButton>
        <CustomButton
          colour="green"
          onClick={() => dispatch(setIsPresenting(true))}
        >
          START
        </CustomButton>
      </div>
    </div>
  );

  return slideData.values ? launchMode : inputMode;
};

export default Launcher;
