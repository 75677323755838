import { configureStore } from '@reduxjs/toolkit';
import sheetReducer from './sheetSlice'
import settingsReducer from './settingsSlice'

export default configureStore({
    reducer: {
        sheet: sheetReducer,
        settings: settingsReducer
    }
})

