import React from "react";
import "./buttonStyle.css";
import { useSelector } from "react-redux";

const CustomButton = ({ children, image, colour, onClick, loading }) => {
  const isLoading = useSelector((state) => state.sheet.loading);

  return (
    <button className={colour} onClick={onClick} type="button">
      {isLoading ? "LOADING..." : children}
    </button>
  );
};

export default CustomButton;
